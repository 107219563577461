<template>
  <v-row class="fill-height">
    <v-col>
      <b-container fluid class="card p-4">
        <b-row>
          <b-col cols="6" class="col-header-calendar">
            <b-button
              class="mr-2 calendar-list-page__body__today-button"
              @click="setToday"
              >Hôm nay
            </b-button>
            <v-btn
              class="mr-2"
              fab
              text
              small
              color="grey darken-2"
              @click="prev"
            >
              <v-icon> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              class="mr-2"
              fab
              text
              small
              color="grey darken-2"
              @click="next"
            >
              <v-icon> mdi-chevron-right</v-icon>
            </v-btn>
            <h4 v-if="$refs.calendar" class="header-calendar-title m-1" style="display: inline">
              {{ $refs.calendar.title }}
            </h4>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <b-row> </b-row>
            <b-button
              class="btn btn-secondary ml-2"
              type="button"
              @click.stop="chooseCourse"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/pencil.svg" />
              </span>
              Chọn khóa học
            </b-button>
            <b-button
              class="btn btn-success ml-2"
              type="button"
              @click.stop="handleSubmit"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Cập nhật lịch
            </b-button>
            <!-- <b-button class="btn btn-danger ml-2" type="button" @click.stop="removeAll">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/trash.svg" />
              </span>
              Xóa tất cả
            </b-button> -->
          </b-col>
        </b-row>
        <b-row class="px-4">
          <h6>
            Coach: {{ coachName }} - Khóa: {{ course.text }} - Ngày bắt đầu khóa
            học: {{ formattedStartDate }} - Số lớp đang đăng ký:
            {{ this.events.length }}
          </h6>
        </b-row>
      </b-container>
      <v-sheet height="550">
        <v-calendar
          ref="calendar"
          v-model="value"
          :event-color="getEventColor"
          :event-ripple="false"
          :events="events"
          color="#c1d1ee"
          type="week"
          @change="getEvents"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mouseleave="cancelDrag"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @click:event="showEvent"
        >
          <template v-slot:event="{ event, timed /*, eventSummary */ }">
            <!--<div class="v-event-draggable">
                <component :is="{ render: eventSummary }"></component>
              </div>-->
            <div
              v-if="timed"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>
        </v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="true"
          :activator="selectedElement"
          :absolute="false"
          value="true"
          bottom
          left
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title>{{ formattedEventTime }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="removeEvent">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
    <select-course-modal @selected-success="loadData()" />
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment-timezone';
const { mapState } = createNamespacedHelpers('calendarCoach');

export default {
  name: 'CoachesCalendar',
  components: {
    'select-course-modal': () => import('./components/SelectCourseModal.vue'),
  },
  data: () => ({
    value: '',
    events: [],
    names: [
      'Meeting',
      'Holiday',
      'PTO',
      'Travel',
      'Event',
      'Birthday',
      'Conference',
      'Party',
    ],
    courseSelected: null,
    coachSelected: null,
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    ready: false,
  }),
  methods: {
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: '#01645A',
          start: this.createStart,
          end: this.roundTime(this.createStart + 30 * 60 * 1000),
          timed: true,
        };

        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        const startDate = new Date(newStart).getDate();
        const endDate = new Date(newEnd).getDate();
        if(startDate === endDate){
          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        }
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        const oldEndDate = new Date(this.createEvent.end).getDate();
        const maxDate = new Date(max).getDate();
        const minDate = new Date(min).getDate();

        if(oldEndDate === maxDate && minDate === maxDate){
          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      }
    },
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute,
      ).getTime();
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color;
    },
    getEvents({ start, end }) {
      const events = [];

      const min = new Date(`${start.date}T00:00:00`).getTime();
      const max = new Date(`${end.date}T23:59:59`).getTime();
      const days = (max - min) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const timed = this.rnd(0, 3) !== 0;
        const firstTimestamp = this.rnd(min, max);
        const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000;
        const start = firstTimestamp - (firstTimestamp % 900000);
        const end = start + secondTimestamp;

        events.push({
          name: this.rndElement(this.names),
          color: '#a0a5a938',
          start,
          end,
          timed,
        });
      }

      // this.events = events
    },
    setToday() {
      this.value = '';
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    showEvent({ nativeEvent, event, day }) {
      if (day.past) {
        this.canRemove = false;
      } else {
        this.canRemove = true;
      }
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true)),
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    formatWeekday(date) {
      return date;
    },
    removeEvent() {
      this.events = this.events.filter((event) => event !== this.selectedEvent);
    },
    chooseCourse() {
      this.$bvModal.show('coaches-calendar-modal');
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    async loadData() {
      const payload = this.removeEmpty({
        CoachId: this.coachId,
        CourseId: this.course.value,
      });
      const params = new URLSearchParams();
      for (const property in payload) {
        params.append(property, payload[property]);
      }
      try {
        this.$store.commit('context/setLoading', true);
        const { data } = await this.$api.get('CalendarCoach/Portal', {
          params: params,
        });
        const sevenHoursInMs = 7 * 60 * 60 * 1000;

        this.events =
          data?.map((el) => {
            return {
              name: `Event #${this.events.length}`,
              color: el.status === 0 ? '#01645A' : '#FF0000',
              start: el.startTime * 1000 - sevenHoursInMs,
              end: el.endTime * 1000 - sevenHoursInMs,
              timed: true,
            };
          }) || [];
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async handleSubmit() {
      if (this.events.length === 0) {
        this.$toastr.e({
          title: 'Thất bại !',
          msg: 'Không được để trống lịch',
        });
        return;
      }
      const finalList = [];
      for (const event of this.events) {
        finalList.push({
          CoachId: this.coachId,
          CourseId: this.course.value,
          Start: moment(event.start).format('MM/DD/YYYY HH:mm'),
          End: moment(event.end).format('MM/DD/YYYY HH:mm'),
          Status: 0,
        });
      }
      const listEventsJson = JSON.stringify(finalList);

      try {
        var isUpdateSuccessful = await this.$api.post(
          'CalendarCoach/CreateMany',
          listEventsJson,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        if (isUpdateSuccessful) {
          this.$toastr.s({
            title: 'Thành công !',
            msg: 'Cập nhật lịch thành công',
          });
        } else {
          this.$toastr.e({
            title: 'Thất bại !',
            msg: 'Cập nhật lịch thất bại',
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$emit('load-data');
      }
    },
    // async removeAll() {
    //   var object = {
    //     CourseId: this.course.value,
    //     CoachId: this.coachId,
    //   };
    //   const objectJson = JSON.stringify(object);
    //   console.log(object);
    //   console.log(objectJson);

    //   const isDeleteSuccessful = await this.$api.delete('CalendarCoach/DeleteMany', objectJson, {
    //       headers: { 'Content-Type': 'application/json' },
    //   });
    //   console.log(isDeleteSuccessful);
    // },
  },
  computed: {
    ...mapState(['coachId', 'course', 'coachName']),
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    formattedEventTime() {
      if (
        this.selectedEvent &&
        this.selectedEvent.start &&
        this.selectedEvent.end
      ) {
        const startTime = moment(this.selectedEvent.start).format('HH:mm');
        const endTime = moment(this.selectedEvent.end).format('HH:mm');
        return `${startTime} - ${endTime}`;
      } else {
        return '';
      }
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },
    formattedStartDate() {
      return this.course.time ? moment.unix(this.course.time).format('DD/MM/YYYY') : '';
    },
  },
  mounted() {
    this.$refs.calendar.scrollToTime('07:00');
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
};
</script>

<style scoped lang="scss">
.v-menu {
  display: inline;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
